.footer {
  background-color: #051423;
  height: 120px;
  text-align: center;
  padding-top: 5px;
}

.footer-content {
  margin: auto;
  display: block;
  color: #ffffff;
  font-size: 0.8em;
}

.footer-link {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}