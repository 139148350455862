.container {
  text-align: center;
  padding: 16px;
  min-height: calc(100vh - 180px);
  padding-top: 70px;
}

.status {
  font-size: 1.25em;
  margin-bottom: .5rem;
}

.stars-container {
  display: inline-block;
  border: 0;
}

.btn-submit {
  display: block !important;
  margin: auto !important;
}

.thank-you-label {
  font-size: 1.50em;
}