.comment-field-container {
  width: 50%;
  margin: auto;
}

.comment-label {
  font-size: 1.25em;
}

.comment-separator {
  background-color: transparent;
  display: block;
  height: 1px;
  border-width: 0;
  border-top: 1px solid #e7e7e7;
  line-height: 19px;
  margin-top: 0;
}