@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.score-select {
  margin-top: 16px;
  margin-bottom: 16px;
}
.star-container {
  display: inline-block;
  border: 0;
}

input {
  display: none;
}

label {
  color: #d9d9d9;
  float: right;
}

label:before {
  display: inline-block;
  font-size: 1.8rem;
  padding: .3rem .2rem;
  margin: 0;
  cursor: pointer;
  font-family: "Font Awesome 5 Free";
  content: "\F005   "; /* full star */
}

.half-star:before {
  content: "\F089   "; /* half star no outline */
  position: absolute;
  padding-right: 0;
}

/* Zero stars rating */
.stars-container label:last-child:before {
  content: "\F005"; /* empty star outline */
}

/* Click + hover color */
input:checked ~ label, /* color current and previous stars on checked */
label:hover, label:hover ~ label { color: #66a3ff; } /* color previous stars on hover */

/* Hover highlights */
input:checked + label:hover, input:checked ~ label:hover, /* highlight current and previous stars */
input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
label:hover ~ input:checked ~ label /* highlight previous selected stars */ { color: #005ce6; } 
.stars-container {
  display: inline-block;
  border: 0;
}

.temp-score {
  font-size: 1.25em;
  color: #005ce6;
}
.comment-field-container {
  width: 50%;
  margin: auto;
}

.comment-label {
  font-size: 1.25em;
}

.comment-separator {
  background-color: transparent;
  display: block;
  height: 1px;
  border-width: 0;
  border-top: 1px solid #e7e7e7;
  line-height: 19px;
  margin-top: 0;
}
.modal-container {
  position: fixed;
  padding: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position: fixed;
  background: white;
  width: 70%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.display {
  display: block !important;
}

.hide {
  display: none !important;
}

.close-button {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 16px !important;
}

.modal-image {
  width: 50px;
  height: 50px;
  margin: auto;
}

.modal-legend {
  font-size: 1.25em;
}
.footer {
  background-color: #051423;
  height: 120px;
  text-align: center;
  padding-top: 5px;
}

.footer-content {
  margin: auto;
  display: block;
  color: #ffffff;
  font-size: 0.8em;
}

.footer-link {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}
.container {
  text-align: center;
  padding: 16px;
  min-height: calc(100vh - 180px);
  padding-top: 70px;
}

.status {
  font-size: 1.25em;
  margin-bottom: .5rem;
}

.stars-container {
  display: inline-block;
  border: 0;
}

.btn-submit {
  display: block !important;
  margin: auto !important;
}

.thank-you-label {
  font-size: 1.50em;
}
.main-container {
  text-align: center;
  padding: 16px;
}

.not-found {
  font-size: 2em;
}
