@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);

.star-container {
  display: inline-block;
  border: 0;
}

input {
  display: none;
}

label {
  color: #d9d9d9;
  float: right;
}

label:before {
  display: inline-block;
  font-size: 1.8rem;
  padding: .3rem .2rem;
  margin: 0;
  cursor: pointer;
  font-family: "Font Awesome 5 Free";
  content: "\f005 "; /* full star */
}

.half-star:before {
  content: "\f089 "; /* half star no outline */
  position: absolute;
  padding-right: 0;
}

/* Zero stars rating */
.stars-container label:last-child:before {
  content: "\f005"; /* empty star outline */
}

/* Click + hover color */
input:checked ~ label, /* color current and previous stars on checked */
label:hover, label:hover ~ label { color: #66a3ff; } /* color previous stars on hover */

/* Hover highlights */
input:checked + label:hover, input:checked ~ label:hover, /* highlight current and previous stars */
input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
label:hover ~ input:checked ~ label /* highlight previous selected stars */ { color: #005ce6; } 