.modal-container {
  position: fixed;
  padding: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position: fixed;
  background: white;
  width: 70%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.display {
  display: block !important;
}

.hide {
  display: none !important;
}

.close-button {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 16px !important;
}

.modal-image {
  width: 50px;
  height: 50px;
  margin: auto;
}

.modal-legend {
  font-size: 1.25em;
}